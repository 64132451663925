<template>
  <ReusableModal
    :visible="whenModalView"
    :style="{ width: '35.5rem' }"
    @dialog-toggle="closeSelfCleanup"
  >
    <div class="when-to w-full flex flex-column justify-content-start align-items-start gap-4">
      <p class="title">{{ $t("When") }}?</p>
      <div class="input-container">
        <div v-if="isAsSoonAsPossible" class="input-option">
          <RadioButton
            v-model="mode"
            variant="filled"
            input-id="ingredient1"
            name="Default"
            :value="HandoverType.ASSOONASPOSSIBLE"
          />
          <label for="ingredient1" class="ml-2 input-label"
            >{{ $t("Assoonaspossible") }} (
            {{ constantTimeAccordingToRestaurant }}
            )
          </label>
        </div>
        <div class="w-full flex flex-column justify-content-start align-items-end gap-2">
          <div class="input-option" @click="handleRadioButtonClick">
            <RadioButton
              v-model="mode"
              variant="filled"
              input-id="ingredient2"
              name="schedule"
              :value="HandoverType.SCHEDULED"
              :disabled="disableScheduleRadioButton"
            />
            <label for="ingredient2" class="ml-2 input-label">{{ $t("later") }}</label>
          </div>
          <div class="w-full flex justify-content-end align-items-center gap-2">
            <Dropdown
              v-model="selectedDate"
              :options="dates"
              class="input-dropdown"
              option-label="name"
              option-value="code"
              placeholder="Dato"
              checkmark
              :highlight-on-select="false"
              :disabled="!(mode === HandoverType.SCHEDULED)"
              :empty-message="$t('nooption')"
            />
            <Dropdown
              v-model="selectedTime"
              :options="times"
              class="input-dropdown"
              option-label="name"
              option-value="code"
              placeholder="Tid"
              :empty-message="$t('nooption')"
              checkmark
              :highlight-on-select="false"
              :disabled="!(mode === HandoverType.SCHEDULED)"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-row justify-content-end align-items-center gap-2">
        <div class="button normal" @click="closeSelfCleanup">{{ $t("Cancel") }}</div>
        <div class="button" @click="handleConfirm">{{ $t("Continue") }}</div>
      </div>
    </div>
  </ReusableModal>
</template>

<script setup lang="ts">
import moment from "moment";
import { HandoverType, OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";
import { useCartSlice } from "~/store/cartSlice";
import { useLocationSlice } from "~/store/locationSlice";
import { getFormattedDates } from "~/utils/dateTime.util";

const { t } = useI18n();
const orderMethodStore = useOrderMethodStore();
const cartStore = useCartSlice();
const {
  whenModalView,
  timeOfHandover,
  constantTimeAccordingToRestaurant,
  openingAndClosingTimeAccordingToMethod,
  selectedMethod,
  selectedRestaurantForDelivery,
} = storeToRefs(orderMethodStore);
const { preparationTimeForCurrentRestaurant, isAsSoonAsPossible } = useAutoSchedule();
const mode = computed({
  get() {
    return timeOfHandover.value.type;
  },
  set(handoverType: HandoverType) {
    orderMethodStore.timeOfHandover.type = handoverType;
  },
});
const generateTimeIntervals = (openingTime: string, closingTime: string) => {
  const intervals = [];
  const startTime = moment(openingTime, "HH:mm").add(
    preparationTimeForCurrentRestaurant.value,
    "minutes",
  );
  const endTime = moment(closingTime, "HH:mm");
  // eslint-disable-next-line no-unmodified-loop-condition
  while (startTime <= endTime) {
    intervals.push({
      name: startTime.format("HH:mm"),
      code: startTime.format("HH:mm").toString(),
    });
    startTime.add(15, "minutes");
  }
  if (selectedDate.value && selectedDate.value === moment().format("YYYY-MM-DD")) {
    return intervals.filter((it) => {
      return !moment(it.code, "HH:mm").isBefore(
        moment().add(preparationTimeForCurrentRestaurant.value, "minutes"),
      );
    });
  }
  return intervals;
};
const selectedDate = ref(null);
const dates = ref(getFormattedDates());
const selectedTime = ref(null);
const locationSlice = useLocationSlice();
const { selectedRestaurant } = storeToRefs(locationSlice);

const handleConfirm = () => {
  if (mode.value === HandoverType.SCHEDULED) {
    if (selectedDate.value && selectedTime.value) {
      orderMethodStore.setDateAndTime(selectedDate.value || "", selectedTime.value || "");
    } else {
      mode.value = HandoverType.ASSOONASPOSSIBLE;
    }
  }
  if (cartStore.cartList.length > 0) {
    cartStore.validateCartItem();
  }
  orderMethodStore.toggleWhenModal();
};
const disableScheduleRadioButton = computed(() => {
  if (selectedMethod.value === OrderMethod.PICKUP && selectedRestaurant.value === null) {
    return true;
  }
  if (
    selectedMethod.value === OrderMethod.DELIVERY &&
    selectedRestaurantForDelivery.value === null
  ) {
    return true;
  }
});

const handleRadioButtonClick = () => {
  if (selectedMethod.value === OrderMethod.PICKUP && selectedRestaurant.value === null) {
    useNuxtApp().$toast.warn(t("selectPickupDelivery"));
  }
  if (
    selectedMethod.value === OrderMethod.DELIVERY &&
    selectedRestaurantForDelivery.value === null
  ) {
    useNuxtApp().$toast.warn(t("selectPickupDelivery"));
  }
};

const times = computed(() => {
  const dayName = moment(selectedDate.value, "YYYY-MM-DD").format("dddd").toUpperCase();
  const dateObj = openingAndClosingTimeAccordingToMethod.value?.find((it) => it.day === dayName);
  if (dateObj) {
    return generateTimeIntervals(dateObj.openingTime, dateObj.closingTime);
  }
});
const closeSelfCleanup = () => {
  if (mode.value === HandoverType.SCHEDULED) {
    if (selectedDate.value && selectedTime.value) {
      orderMethodStore.setDateAndTime(selectedDate.value || "", selectedTime.value || "");
    } else if (isAsSoonAsPossible.value) {
      mode.value = HandoverType.ASSOONASPOSSIBLE;
    }
  }
  if (cartStore.cartList.length > 0) {
    cartStore.validateCartItem();
  }
  orderMethodStore.toggleWhenModal();
};
</script>

<style scoped>
/* Use deep selector for scoped styles */
:deep(.p-radiobutton-box) {
  border-color: indianred;
}

:deep(.p-radiobutton.p-variant-filled.p-highlight .p-radiobutton-box) {
  background: #cd1719;
}
</style>
