<template>
  <div class="cart-item-container" :class="isProductUnavailable ? 'disabled' : ''">
    <div v-if="isProductUnavailable" class="blocker">
      <div class="cross-button" @click="removeItemFromCart">
        <i class="pi pi-times text-xl text-white" />
      </div>
      <p class="error-title">{{ $t("notAvailable") }}</p>
    </div>
    <div class="image-container">
      <NuxtImg
        class="cart-item-image"
        :src="props.itemProps.product.img"
        :placeholder="loadingBox"
        alt="hello"
      >
        <template #error>
          <img :src="loadingBox" :alt="props.p.name" class="cart-item-image" />
        </template>
      </NuxtImg>
    </div>
    <div class="description">
      <p class="title">
        {{ props.itemProps.product.secondHalf ? "Half & Half" : props.itemProps.product.name }}
      </p>
      <div v-if="!isProductUnavailable && !props.itemProps.product.isDeal" class="subtitles">
        <p>
          {{ props.itemProps.product.size || ""
          }}{{ props.itemProps.product.orderAsHalfBaked ? "/half baked" : "" }}
        </p>
        <p v-if="props.itemProps.product.secondHalf">{{ props.itemProps.product.name }}</p>
        <p v-if="props.itemProps.product.secondHalf">
          {{ props.itemProps.product.secondHalf.name }}
        </p>
        <p v-if="props.itemProps.product.customizableOptions.includedItem.length">
          +
          {{
            props.itemProps.product.customizableOptions.includedItem.reduce((acc, curr) => {
              return acc + " " + curr.name;
            }, "")
          }}
        </p>
        <p v-if="props.itemProps.product.customizableOptions.excludedItems.length">
          -
          {{
            props.itemProps.product.customizableOptions.excludedItems.reduce((acc, curr) => {
              return acc + " " + curr.name;
            }, "")
          }}
        </p>
      </div>
      <p v-if="!isProductUnavailable" class="price">
        {{ props.itemProps.quantity * dynamicPrice }},-
      </p>
    </div>
    <div v-if="!isProductUnavailable && showQuantity" class="quantity">
      <div class="action-remove" @click="decreaseItem">
        <IconsDelete v-if="props.itemProps.quantity <= 1" />
        <i v-else class="pi pi-minus"></i>
      </div>
      <p class="product-count">{{ props.itemProps.quantity }}</p>
      <div class="action-increase" @click="increaseItem">
        <IconsAdd />
      </div>
    </div>
  </div>
  <div
    v-if="!props.itemProps.product.isDeal"
    class="w-full"
    :class="isProductUnavailable ? 'mt-2' : ''"
  >
    <Divider />
  </div>
</template>

<script setup lang="ts">
import loadingBox from "~/assets/logo/loading_box.svg";
import type { CartItem } from "~/types/componentProps/cart";
import { useCartSlice } from "~/store/cartSlice";

const props = defineProps({
  itemProps: {
    type: Object as PropType<CartItem>,
    default: () => {},
  },
  index: {
    type: Number,
    default: -1,
  },
  showQuantity: {
    type: Boolean,
    default: true,
  },
});
const cartStore = useCartSlice();
const decreaseItem = () => {
  if (props.itemProps.quantity > 1) {
    cartStore.decreaseQuantity(props.index);
  } else if (props.itemProps.quantity === 1) {
    cartStore.removeItemFromCart(props.index);
  }
};
const increaseItem = () => {
  cartStore.increaseQuantity(props.index);
};
const dynamicPrice = computed(() => {
  return (
    props.itemProps.product.price +
    (props.itemProps.product.secondHalf ? props.itemProps.product.secondHalf.price : 0) +
    props.itemProps.product.customizableOptions.includedItem.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0)
  );
});
const isProductUnavailable = computed(() => {
  return !props.itemProps.product.isAvailable;
});
const removeItemFromCart = () => {
  cartStore.removeItemFromCart(props.index);
};
</script>
