<template>
  <div class="item-container w-full">
    <div class="logo-container">
      <img :src="logoSwitcher(logoType)" alt="logo" />
    </div>
    <div class="content-container">
      <p class="title">{{ logoType === "INVOICE" ? $t("invoice") : ($t(title) ?? title) }}</p>
      <p class="sub-title">{{ subTitle }}</p>
    </div>
    <div class="status-container">
      <img v-if="status" :src="tickMark" alt="logo" />
    </div>
  </div>
</template>

<script setup lang="ts">
import vippsLogo from "~/assets/logo/vipps-small.svg";
import cardIcon from "~/assets/logo/credit-card.svg";
import tickMark from "~/assets/logo/tick-mark.svg";
import Invoice from "~/assets/logo/invoice-logo.svg";
import userIcon from "~/assets/logo/business-user-icon.svg";

const logoSwitcher = (logo: string) => {
  switch (logo) {
    case "VIPPS":
      return vippsLogo;
    case "CARD":
    case "BANKTERMINAL":
      return cardIcon;
    case "INVOICE":
      return Invoice;
    default:
      return userIcon;
  }
};

defineProps({
  logoType: {
    type: String,
    default: "VIPPS",
  },
  title: {
    type: String,
    default: "",
  },
  subTitle: {
    type: String,
    default: "",
  },
  status: {
    type: Boolean,
    default: false,
  },
  cardId: {
    type: String,
    default: "",
  },
});
</script>
