<template>
  <div class="w-full flex-column justify-content-start align-content-start relative">
    <slot />
    <div
      class="flex flex-column justify-content-start align-content-start pl-2 check-disable"
      :class="!isAvailable ? 'disable' : ''"
    >
      <div class="deal-cart-subitem-container">
        <div
          v-for="itemProps of subProduct"
          :key="itemProps.name + itemProps.id"
          class="cart-item-container"
        >
          <div class="image-container">
            <NuxtImg
              class="cart-item-image"
              :src="itemProps.img"
              :placeholder="loadingBox"
              alt="hello"
            >
              <template #error>
                <img :src="loadingBox" :alt="itemProps.name" class="cart-item-image" />
              </template>
            </NuxtImg>
          </div>
          <div class="description">
            <p class="title" :class="!itemProps.isAvailable ? 'muted' : ''">
              {{ itemProps.name }}
            </p>
            <p>{{ sizeLocale(itemProps.size) }}</p>
            <p v-if="!itemProps.isAvailable" class="error-title">{{ $t("notAvailable") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mt-2">
      <Divider />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CustomizationElementType } from "~/types/entities/product.type";
import loadingBox from "assets/logo/loading_box.svg";

const { t } = useI18n();
defineProps({
  subProduct: {
    type: Array as PropType<CustomizationElementType[]>,
    default: () => [],
  },
  isAvailable: {
    type: Boolean,
    default: true,
  },
});
const sizeLocale = (size: string) => {
  switch (size) {
    case "LARGE":
      return t("large");
    case "SMALL":
      return t("mall");
    default:
      return "";
  }
};
</script>

<style scoped></style>
